import React from 'react';
import '../../utility/csrf';
import AmountWrapper from '../../components/amountWrapper';
import NextButton from '../../components/nextButton';
import OneTouchNextButton from '../../components/landing/OneTouchNextButton';
import CancelAndReturn from '../../components/cancelAndReturn';
import GuestButton from '../../components/guestButton';
import UserDetailsBanner from '../../components/onetouch/UserDetailsBanner';
import OfferDetail from '../../components/offerDetail';
import Disclaimer from '../../components/Disclaimer';
import VoucherFlowDisclaimer from '../../components/voucherFlowDisclaimer';
import CloseButton from '../../components/CloseButton';
import ReceiverEligibilityErrorTile from '../error/receiverEligibilityError';
import { Link } from '@paypalcorp/pp-react';
import { Alert } from '@paypalcorp/pp-react-alert';
import { updateGlobalFPTI } from '../../fpti/helper';
import { FPTI_TAGS } from '../../fpti/fptiConstants';
import { getWorldReady } from '../../utility/worldReadyUtils';
import {
  isInContext,
  isMobileOrInContext,
} from '../../utility/inContextDonation';
import DonateHeader from '../../components/common/DonateHeader';

import templateData from '../../utility/getTemplateData';
import {
  hasPersonalizedMessages,
  getPersonalizedMessages,
} from '../../utility/personalizedMessages';

import AppStore from '../../stores/AppStore';
import * as AppActions from '../../actions/AppActions';
import AppDispatcher from '../../dispatcher/AppDispatcher';
import * as cpl from '../../utility/cpl';
import { GN_CONTENT_TOUCHPOINT, EVENT_NAME } from '../../utility/constants';
import PropTypes from 'prop-types';
import {
  handleLogIn,
  addShake,
  handleTokenReuse,
  hideSpinnerOverlay,
  showSpinnerOverlay,
} from '../../utility/helper';
import {
  isAuthenticatedUser,
  shouldShowUserBanner,
  isLoggedInUser,
} from '../../utility/authUtils';
import * as amountUtils from '../../components/amount/amountUtils';

import { withRouter } from 'react-router-dom';
import {
  isOldButtonFactory,
  hasPartnerSource,
} from '../../utility/getTemplateData';
import { isCampaigns } from '../../utility/productIntegrationUtils';
import { loadBodyMovin, waitForOnload } from '../../utility/lazyLoader';
import _isEmpty from 'lodash/isEmpty';
import Programs from '../../components/programs/Programs';
import RecurringOverlay from '../../components/recurring-checkbox/RecurringOverlay';
import _get from 'lodash/get';
import NotTaxDeductibleLegalText from '../../components/NotTaxDeductibleLegalText';
import { getParameterByName } from '../../utility/queryStringHelper';
import CoverFeeCheckbox from '../../components/coverFee/coverFeeCheckbox';
import {
  computeAmountAfterFee,
  isOptInCoverFee,
  restoreDonationAmount,
  updateDonationAmount,
  setFeeAmountInStore,
} from '../../components/coverFee/feeUtils';
import withDonate from '../../components/guest/WithDonate';
import withTheme from '../../components/withTheme';
import AnonymousCheckbox from '../../components/anonymousDonation/anonymousCheckbox';
import {
  getPersonalizedAnonymousContent,
  getPersonalizedNoteToPayeeLabel,
  getPersonalizedNoteToPayeeTextboxCommentLabel,
  isCheckoutRail,
  isExternalFlow,
  prepareOrderParams,
} from '../../utility/productIntegrationUtils';
import NoteComponent from '../../components/note';
import isEmpty from 'lodash/isEmpty';
import {
  trackAnonymousCheckboxClick,
  trackCoverFeeCheckboxClick,
  trackDonateWithCardClick,
  trackDonateWithPayPalClick,
  trackLandingPageIM,
  trackNextClickForCheckout,
  trackLinkClick,
} from '../../fpti/fpti';
import { CPL_PAGE_SPEC, FEATURES } from '../../fpti/fptiConstants';
import classNames from 'classnames';
import { BodyText, CaptionText, HeadingText } from '@paypalcorp/pp-react';
import { addFeature, setEnabledRecurringFrequency } from '../../fpti/helper';
import withWorldReady from '../../components/withWorldReady';
import {
  Message,
  Context as WorldReadyContext,
} from '@paypalcorp/worldready-react';
import RecurringComponent from '../../components/recurring/recurring';
import { RECURRING_EXPERIMENT } from '../../../../lib/constants';
import RecurringInfo from '../../components/recurring/RecurringInfo';
import { MessageFormat } from '@paypalcorp/worldready';

class LandingPageComponent extends React.Component {
  constructor(props) {
    super(props);
    const optInStatus = AppStore.getCoverFeeOptInStatus();
    const anonymousStatusInStore = AppStore.getAnonymousStatus();
    const anonymousOptInStatus = !isEmpty(anonymousStatusInStore)
      ? anonymousStatusInStore === 'checked'
      : templateData.anonymousStatus || false;

    var cipStatus = null;
    if (getParameterByName('cipStatus') === 'PENDING') {
      cipStatus = 'pending';
    } else if (getParameterByName('cipStatus') === 'FAILED') {
      cipStatus = 'failed';
    }
    updateGlobalFPTI({
      [FPTI_TAGS.SOURCE]: 'adroit',
      [FPTI_TAGS.STATUS]: getParameterByName('cipStatus'),
      [FPTI_TAGS.PROCESS_STATUS]: getParameterByName('processStatus'),
    });
    this.state = {
      hasLogo: templateData.hasLogo,
      subHeaderLabel: templateData.donationName,
      orgName: templateData.charityName,
      donationId: templateData.donationId,
      guestRedirectUrl: templateData.guestRedirectUrl,
      donateDisabled: false,
      recurringEnabled: templateData.recurringEnabled,
      recurringStatus: templateData.recurringStatus === 'checked',
      supportedRecurringFrequencies: templateData.supportedRecurringFrequencies,
      supportedRecurringHelperText: templateData.supportedRecurringHelperText,
      recurringHelpText: '',
      recurringLabel: 'Make this a monthly donation',
      rpContinue: false,
      isAuthenticated: isAuthenticatedUser(),
      memberMandatory: templateData.memberMandatory,
      programNames:
        templateData.donateContext?.program_names || templateData.programNames,
      isRecurringOverlayOpen: false,
      isVoucherFlow: templateData.isVoucherFlow,
      offerProgram: templateData.offerProgram,
      coverFeeEnabled: templateData.coverFeeEnabled,
      coverFeeOptInStatus:
        optInStatus !== undefined
          ? optInStatus === 'checked'
          : templateData.feePayer === 'consumer',
      feeAmount: '',
      formatedFeeAmount: '',
      anonymousEnabled: templateData.anonymousEnabled,
      anonymousOptInStatus,
      hasDisclaimer: hasPersonalizedMessages(GN_CONTENT_TOUCHPOINT),
      cipStatus: cipStatus,
      recurringFlow:
        templateData.recurringVisualExperiment ||
        RECURRING_EXPERIMENT.TREATENTS.Ctrl_Giving_donate_recurring_checkbox,
    };
  }

  componentDidMount = () => {
    window.onload = function () {
      setTimeout(function () {
        cpl.logPerformance(CPL_PAGE_SPEC.LANDING_PAGE);
      }, 0);
    };

    AppActions.updateCurrentPage({ currentPage: 'landing' });

    // Do not hide spinner if coming to page with already completed payment token
    if (templateData.paymentStatus !== 'complete') {
      hideSpinnerOverlay();
    }

    // Adds recurring feature if there is more than one supported frequency
    setEnabledRecurringFrequency();

    if (templateData?.supportedRecurringFrequencies?.length > 1) {
      addFeature(FEATURES.RECURRING);
    }

    // Checking for cover fee preference if coming from guest page
    if (isOptInCoverFee()) {
      this.setState({ coverFeeOptInStatus: true });
      restoreDonationAmount();
    }

    setFeeAmountInStore();
    this.updateFeeAmount();

    trackLandingPageIM({
      noteFieldDisplayed: this.shouldShowNote(),
      donationFraudEligibility: String(templateData.donationFraudEligibility),
    });

    // Pre-load bodyMovin
    if (templateData.recurringEnabled) {
      waitForOnload().then(() => {
        loadBodyMovin();
      });
    }

    if (!isLoggedInUser()) {
      AppActions.showLanguageSelector(true);
    }

    // Register for App Dispatcher Messages
    this.registerAppDispatcherMessages();
  };
  updateCipAmountValidation = (showError) => {
    if (showError) {
      this.setState({ showValidationError: true });
      const getValidationErrorMessage = this.getValidationErrorMessage();
      this.setState({
        validationErrorMessage: getValidationErrorMessage,
      });
    } else {
      this.setState({ showValidationError: false });
      this.setState({
        validationErrorMessage: null,
      });
    }
  };

  getValidationErrorMessage() {
    const worldReady = getWorldReady();
    const wrmessage = new MessageFormat(worldReady, {
      id: 'landing.cip.pendingAmountError',
    }).format({
      maximumAllowedDonation: amountUtils.formatCurrencyWithoutDecimals({
        value: 999,
        currency: templateData.donationCode,
      }),
    });
    return wrmessage;
  }

  componentWillUnmount() {
    AppDispatcher.unregister(this.dispatcherMessages);
  }

  registerAppDispatcherMessages = () => {
    this.dispatcherMessages = AppDispatcher.register((payload) => {
      if (payload.action && payload.action.actionType === 'CHANGE_RECURRING') {
        const checked = _get(payload.action, 'item', 'unchecked');
        this.setState({
          recurringStatus: checked === 'checked',
        });
      }
    });
  };

  getFPTDATA = () => {
    return {
      amount: AppStore.getDonationAmount(),
      code: AppStore.getDonationCode(),
      symbol: AppStore.getDonationSymbol(),
    };
  };

  validateAmountAndNavigateToGuestPage = () => {
    let donationAmount = AppStore.getDonationAmount();

    if (amountUtils.isAmountGreaterThanZero(donationAmount)) {
      trackDonateWithCardClick({
        noteFieldDisplayed: this.shouldShowNote(),
        fptiData: {
          is_recurring: this.state.recurringStatus ? 'on' : 'off',
          fee_type: this.state.coverFeeEnabled ? 'offset' : 'none',
          donation_fraud_eligible: String(
            templateData.donationFraudEligibility
          ),
        },
      });
      this.props.history.push(`/donate/guest?token=${templateData.token}`);
    } else {
      addShake();
    }
  };

  handleGuest = (event) => {
    event.preventDefault();
    AppActions.changeRecurringModalStatus({
      recurringModalStatus: 'notclosed',
    });
    if (!this.validateDonationAmount()) {
      return;
    }

    if (amountUtils.isAmountGreaterThanZero(AppStore.getDonationAmount())) {
      const isRecurringChecked = AppStore.getDonationRecurring() === 'checked';

      if (isRecurringChecked && !this.state.memberMandatory) {
        this.openRecurringOverlay();
      } else {
        this.getDonationAmount();
        this.validateAmountAndNavigateToGuestPage();
      }

      // Start CPL tracking to get measurements to load guest form
      window.PAYPAL.analytics.startCPLTracking({
        page: 'main:donate:wps:guest:guestform:guestFormComponent:web::',
        action: 'guest_form_loaded',
        uicomp: 'guestForm',
        uitype: 'component',
      });
    }
  };

  handleNext = (event) => {
    event.preventDefault();
    if (!this.validateDonationAmount()) {
      return;
    }

    let donationAmount = this.getDonationAmount();
    if (amountUtils.isAmountGreaterThanZero(donationAmount)) {
      trackDonateWithPayPalClick({
        noteFieldDisplayed: this.shouldShowNote(),
        fptiData: {
          is_recurring: this.state.recurringStatus ? 'on' : 'off',
          fee_type: this.state.coverFeeEnabled ? 'offset' : 'none',
          donation_fraud_eligible: String(
            templateData.donationFraudEligibility
          ),
        },
      });
      handleLogIn();
    }
  };

  handleNextForCheckout = (event) => {
    event.preventDefault();
    if (!this.validateDonationAmount()) {
      return;
    }

    trackNextClickForCheckout({ noteFieldDisplayed: this.shouldShowNote() });
    showSpinnerOverlay();
    const orderParams = prepareOrderParams();
    this.props.createOrder(orderParams);
  };

  getDonationAmount = () => {
    let donationAmount = AppStore.getDonationAmount();

    if (isOptInCoverFee()) {
      const feeAmount = AppStore.getFeeAmount();
      // backup user's original entered amount in store
      AppActions.changeAmountBeforeFee({ amountBeforeFee: donationAmount });

      if (feeAmount) {
        donationAmount = computeAmountAfterFee(donationAmount, feeAmount);
        updateDonationAmount(donationAmount);
      }
    }

    return donationAmount;
  };

  hideValidationError = () => {
    this.setState({
      showValidationError: false,
    });
  };

  validateDonationAmount = () => {
    let validationResult =
      amountUtils.validateMinimumDonationAmount('common.amount');
    if (validationResult?.showValidationError) {
      this.setState(validationResult);
      addShake();
      return false;
    }

    validationResult = amountUtils.validateMaximumDonationAmount();
    if (validationResult?.showValidationError) {
      this.setState(validationResult);
      addShake();
      return false;
    }

    this.setState(validationResult);
    return true;
  };

  handleAmountFocus = () => {
    if (
      this.state.showValidationError &&
      (!this.cipFailedOrPending() ||
        (this.cipFailedOrPending() &&
          Number(AppStore.getDonationAmount()) < 1000))
    ) {
      this.hideValidationError();
    }
  };

  updateConversionSataus = (status = false) => {
    this.setState({ donateDisabled: status });
  };

  handleRecurringChange = (status = false) => {
    this.setState({ recurringStatus: status });
  };

  handleRecurringOverlayContinue = () => {
    this.setState({ rpContinue: true });
    this.getDonationAmount();
    this.validateAmountAndNavigateToGuestPage();
  };

  handleRecurringOverlayMakeOneTimeDonation = () => {
    AppActions.changeRecurring({
      recurring: 'unchecked',
    });
    AppActions.changeSelectedRecurringFrequency({
      selectedRecurringFrequency: RECURRING_EXPERIMENT.VALUE.ONE_TIME,
    });
    this.getDonationAmount();
    this.validateAmountAndNavigateToGuestPage();
  };

  handleAnonymousDonationChange = (status = false) => {
    this.setState({ anonymousOptInStatus: status });
    trackAnonymousCheckboxClick(status);
  };

  handleCoverFeeChange = (status = false) => {
    this.setState({ coverFeeOptInStatus: status });
    trackCoverFeeCheckboxClick(status);
  };

  updateFeeAmount = () => {
    this.setState({
      feeAmount: AppStore.getFeeAmount(),
      formatedFeeAmount: AppStore.getFormatedFeeAmount(),
    });
  };

  openRecurringOverlay = () => {
    this.setState({
      isRecurringOverlayOpen: true,
    });
  };

  closeRecurringOverlay = () => {
    this.setState({
      isRecurringOverlayOpen: false,
    });
  };

  renderProgramsDropdown = () => {
    if (isOldButtonFactory() || _isEmpty(this.state.programNames)) {
      return null;
    }

    return <Programs programNames={this.state.programNames} />;
  };

  renderDonateButtons = () => {
    if (isCheckoutRail()) {
      return (
        <OneTouchNextButton
          disabled={false}
          handleNext={this.handleNextForCheckout}
        />
      );
    }

    if (this.state.isAuthenticated) {
      return (
        <OneTouchNextButton
          disabled={this.state.donateDisabled}
          handleNext={this.handleNext}
        />
      );
    }

    return (
      <div>
        <div className="mb-16">
          <NextButton
            disabled={this.state.donateDisabled}
            handleNext={this.handleNext}
          />
        </div>
        <GuestButton
          disabled={this.state.donateDisabled}
          handleGuest={this.handleGuest}
        />
      </div>
    );
  };

  /**
   * Should show note on landing page when charityNote is enabled
   * and recurring is disabled for client flows
   * @return {Boolean} true to show note field
   */
  shouldShowNote = () => {
    const showNoteCondition =
      templateData.charityNoteEnabled &&
      !this.state.recurringStatus &&
      isExternalFlow();

    if (showNoteCondition) {
      addFeature(FEATURES.CHARITY_NOTE);
    }

    return showNoteCondition;
  };

  getPersonalizedContent = () => {
    if (isExternalFlow()) {
      const personalizedMessages = getPersonalizedMessages(
        GN_CONTENT_TOUCHPOINT
      );
      return personalizedMessages?.disclaimer;
    }
  };

  landingClass() {
    return classNames({
      gn_donate_wrapper: templateData.bannerUrl,
      donate_wrapper: !templateData.bannerUrl,
      context_gn_wrapper: isInContext(),
      'user-banner': shouldShowUserBanner(),
      mobile_view: isMobileOrInContext(),
    });
  }

  shouldShowSupportText = () => {
    const amountRaised = templateData.campaignTotalAmountRaised || '0';
    const campaignStatus = templateData.campaignStatus;
    return (
      campaignStatus === 'INACTIVE' &&
      +amountRaised >= +templateData.campaignTargetAmount
    );
  };

  shouldShowNoDonationText = () => {
    const amountRaised = templateData.campaignTotalAmountRaised || '0';
    const campaignStatus = templateData.campaignStatus;
    return (
      campaignStatus === 'INACTIVE' &&
      ((!templateData.campaignEndTime && amountRaised === '0') ||
        templateData.campaignEndTime !== '')
    );
  };

  cipFailedOrPending = () => {
    return (
      this.state.cipStatus === 'failed' || this.state.cipStatus === 'pending'
    );
  };

  trackReportMechantClicks = () => {
    trackLinkClick('REPORT_MERCHANT', {
      eventName: EVENT_NAME.DONATE_DONOR_VIEW_REPORT_MERCHANT_PRESSED,
      additionalData: {
        [FPTI_TAGS.FLOW]: templateData.product,
        [FPTI_TAGS.ENCR_RCVR_ID]: templateData.merchantId,
        [FPTI_TAGS.MERCHANT_EMAIL]: templateData.donationEmail,
      },
    });
  };

  renderDonationFraudMessage = () => {
    return (
      <Alert type="info" id="donation_fraud_alert">
        <Message
          id="landing.donationFraud.disclaimer"
          link={(innerMessage) => (
            <Link
              onClick={this.trackReportMechantClicks}
              href="/us/cshelp/aupviolation"
            >
              {innerMessage}
            </Link>
          )}
        />
      </Alert>
    );
  };
  render() {
    const showUserBanner = shouldShowUserBanner();
    const showSupportText = this.shouldShowSupportText();
    const showNoDonationText = this.shouldShowNoDonationText();

    const { TREATENTS } = RECURRING_EXPERIMENT;
    const {
      Ctrl_Giving_donate_recurring_checkbox,
      Trmt_1_Giving_donate_segmented_controller,
      Trmt_2_Giving_donate_selection_group_header_above,
      Trmt_3_Giving_donate_selection_group_header_bellow,
    } = TREATENTS;

    const shouldShowRecurringDisclaimer =
      AppStore.getDonationRecurring() === 'checked' &&
      ((this.state.recurringFlow === Ctrl_Giving_donate_recurring_checkbox &&
        templateData.supportedRecurringFrequencies?.length === 3) ||
        Trmt_1_Giving_donate_segmented_controller ===
          this.state.recurringFlow ||
        Trmt_2_Giving_donate_selection_group_header_above ===
          this.state.recurringFlow ||
        Trmt_3_Giving_donate_selection_group_header_bellow ===
          this.state.recurringFlow);

    const shouldRenderRecurringVisualExperimentAboveAmount =
      this.state.recurringEnabled &&
      !isCampaigns() &&
      (this.state.recurringFlow === Trmt_1_Giving_donate_segmented_controller ||
        this.state.recurringFlow ===
          Trmt_2_Giving_donate_selection_group_header_above);

    const shouldRenderRecurringVisualExperimentBelowAmount =
      this.state.recurringEnabled &&
      !isCampaigns() &&
      this.state.recurringFlow ===
        Trmt_3_Giving_donate_selection_group_header_bellow;

    const shouldRenderRecurringVisualExperimentsCheckbox =
      this.state.recurringEnabled &&
      (this.state.recurringFlow === Ctrl_Giving_donate_recurring_checkbox ||
        isCampaigns());

    if (!templateData.receiverCountryEligible) {
      return (
        <div
          className={`receiver-eligibility-error-container fullPageError ${
            isMobileOrInContext() ? 'mobile_view' : ''
          }`}
        >
          <ReceiverEligibilityErrorTile
            organizationName={templateData.donationName}
            returnUrl={templateData.charityReturnUrl}
          />
        </div>
      );
    }

    return (
      <div>
        {this.cipFailedOrPending() && (
          <Alert
            type={this.state.cipStatus === 'pending' ? 'info' : 'warning'}
            id="cip_warning_banner"
          >
            <Message
              id={
                this.state.cipStatus === 'pending'
                  ? 'landing.cip.pendingDisclaimer'
                  : 'landing.cip.failedDisclaimer'
              }
              link={(innerMessage) =>
                this.state.cipStatus === 'failed' ? (
                  <Link href="/donate/buttons">{innerMessage}</Link>
                ) : null
              }
              maximumAllowedDonation={amountUtils.formatCurrencyWithoutDecimals(
                { value: 999, currency: templateData.donationCode }
              )}
            />
          </Alert>
        )}
        <div className={this.landingClass()}>
          {showUserBanner && !showNoDonationText && !showSupportText ? (
            <UserDetailsBanner userDetails={templateData.userDetails} />
          ) : null}
          {isExternalFlow() && <CloseButton inverse />}
          <DonateHeader isLanding={true} />
          {(() => {
            switch (true) {
              case showSupportText:
                return (
                  <HeadingText
                    size="sm"
                    className="text-center text-padding-top"
                  >
                    <Message id="landing.campaigns.supportText" />
                  </HeadingText>
                );
              case showNoDonationText:
                return (
                  <BodyText className="text-center text-padding-top">
                    <Message id="landing.campaigns.noDonationText" />
                  </BodyText>
                );
              default:
                return (
                  <div
                    className={classNames({
                      container: true,
                      campaigns: isCampaigns(),
                    })}
                  >
                    {shouldRenderRecurringVisualExperimentAboveAmount && (
                      <RecurringComponent
                        flow={this.state.recurringFlow}
                        recurringFrequencies={
                          templateData.supportedRecurringFrequencies
                        }
                      />
                    )}
                    {templateData.enablePresetAmounts &&
                      shouldRenderRecurringVisualExperimentBelowAmount && (
                        <CaptionText>
                          <Message id={`common.amount.label.amount`} />
                        </CaptionText>
                      )}
                    <AmountWrapper
                      conversionStatus={this.updateConversionSataus}
                      validateDonationAmount={this.validateDonationAmount}
                      showValidationError={this.state.showValidationError}
                      updateCipAmountValidation={this.updateCipAmountValidation}
                      hideValidationError={this.hideValidationError}
                      validationErrorMessage={this.state.validationErrorMessage}
                      validationErrorCode={this.state.validationErrorCode}
                      onFocus={this.handleAmountFocus}
                      updateFeeAmount={this.updateFeeAmount}
                      cipStatus={this.state.cipStatus}
                    />
                    {shouldRenderRecurringVisualExperimentBelowAmount && (
                      <RecurringComponent
                        flow={this.state.recurringFlow}
                        recurringFrequencies={
                          templateData.supportedRecurringFrequencies
                        }
                      />
                    )}
                    {this.renderProgramsDropdown()}
                    {!isCampaigns() && this.state.anonymousEnabled && (
                      <AnonymousCheckbox
                        content={getPersonalizedAnonymousContent()}
                        optInStatus={this.state.anonymousOptInStatus}
                        handleAnonymousDonationChange={
                          this.handleAnonymousDonationChange
                        }
                      />
                    )}
                    {this.shouldShowNote() && (
                      <NoteComponent
                        label={getPersonalizedNoteToPayeeLabel()}
                        texboxLabel={getPersonalizedNoteToPayeeTextboxCommentLabel()}
                      />
                    )}
                    {this.state.coverFeeEnabled && (
                      <CoverFeeCheckbox
                        handleCoverFeeChange={this.handleCoverFeeChange}
                        optInStatus={this.state.coverFeeOptInStatus}
                        formatedFeeAmount={this.state.formatedFeeAmount}
                      />
                    )}
                    {shouldRenderRecurringVisualExperimentsCheckbox && (
                      <RecurringComponent
                        flow={Ctrl_Giving_donate_recurring_checkbox}
                        recurringFrequencies={
                          templateData.supportedRecurringFrequencies
                        }
                        isCampaigns={isCampaigns()}
                      />
                    )}
                    {this.state.isVoucherFlow && (
                      <OfferDetail
                        offerProgram={this.state.offerProgram}
                        success={false}
                      />
                    )}

                    {shouldShowRecurringDisclaimer && <RecurringInfo />}

                    {templateData.donationFraudEligibility &&
                      !templateData.donationFraudMessageBelowButtons &&
                      this.renderDonationFraudMessage()}
                    {this.renderDonateButtons()}
                    {this.state.isVoucherFlow && <VoucherFlowDisclaimer />}
                    {hasPartnerSource() && !this.state.isVoucherFlow && (
                      <NotTaxDeductibleLegalText />
                    )}
                    <RecurringOverlay
                      onContinue={this.handleRecurringOverlayContinue}
                      onMakeOneTimeDonation={
                        this.handleRecurringOverlayMakeOneTimeDonation
                      }
                      isOpen={this.state.isRecurringOverlayOpen}
                      openRecurringOverlay={this.openRecurringOverlay}
                      closeRecurringOverlay={this.closeRecurringOverlay}
                    />
                    {this.state.hasDisclaimer && (
                      <Disclaimer content={this.getPersonalizedContent()} />
                    )}
                  </div>
                );
            }
          })()}
        </div>
        {templateData.donationFraudEligibility &&
          templateData.donationFraudMessageBelowButtons &&
          this.renderDonationFraudMessage()}
        <CancelAndReturn />
      </div>
    );
  }
}

LandingPageComponent.propTypes = {
  history: PropTypes.object,
};

LandingPageComponent.contextType = WorldReadyContext;

const LandingPage = withRouter(
  withWorldReady(withDonate(withTheme(LandingPageComponent)))
);

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayLanding: !templateData.currentState,
    };
  }

  /**
   * For DIRECT(nonWebscr) and POST integrations, there will not be a token in the URL
   * This will break page refresh.
   * Hence adding token here
   */
  addTokenToUrlIfMissing() {
    const token = getParameterByName('token');
    const business = getParameterByName('business');
    const hostedButtonId = getParameterByName('hosted_button_id');
    const campaignId = getParameterByName('campaign_id');

    if (!token && !business && !hostedButtonId && !campaignId) {
      this.props.history.replace({
        pathname: '/donate',
        search: `?token=${templateData.token}`,
      });
    }
  }

  componentDidMount() {
    if (!this.state.displayLanding) {
      this.props.history.push(templateData.currentState);
    } else {
      handleTokenReuse();
    }
    this.addTokenToUrlIfMissing();
  }

  render() {
    return <div>{this.state.displayLanding ? <LandingPage /> : null}</div>;
  }
}

Landing.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Landing);
